import {
  ApiIntegrationNames,
  EquipmentType,
  IntegrationService,
  ProjectType,
  SolePlateOrBasement,
  TemplateName,
  WebhookEvent,
  WebhookTrigger,
} from 'graphql/types.generated';
import planningCategories from './planningCategoriesEn';
import { simpleDecrypt } from 'utils/helpers';

export default {
  translation: {
    yes: 'Yes',
    no: 'No',
    success: 'Success',
    buttons: {
      next: 'Next',
      download: 'Download',
      finish: 'Finish',
      submit: 'Submit',
      cancel: 'Cancel',
      confirm: 'Confirm',
      search: 'Search',
      send: 'Send',
      back: 'Back',
      apply: 'Apply',
      refresh: 'Refresh',
      generate: 'Generate',
      proceed: 'Proceed',
      save: 'Save',
      close: 'Close',
      retry: 'Retry',
      delete: 'Delete',
      add: 'Add',
    },
    copiedToClipboard: 'Copied to clipboard',
    locales: {
      en: 'Englisch',
      de: 'Deutsch',
    },
    roles: {
      MEINBAU_ADMIN: 'MeinBau Admin',
      TENANT_ADMIN: 'Tenant Admin',
    },
    forms: {
      optionalLabel: '(optional)',
      selected: 'Selected -',
      searchByName: 'Name',
      noOptions: 'No options',
    },
    table: {
      emptyDataMessage: 'No data found',
    },
    auth: {
      firstName: 'First name',
      lastName: 'Last name',
      role: 'Role',
      inviter: 'Invited by',
      email: 'Email',
      password: 'Password',
      repeatNewPassword: 'Repeat the new password',
      passwordRules: 'At least 8 characters long, including: 1 upper case; 1 lower case; 1 special character',
      termsOfUseLabel: 'I accept the <termsOfUseUrl>Terms of use</termsOfUseUrl>',
      privacyPolicyLabel:
        'Information on data processing can be found here - <privacyPolicyUrl>Privacy Policy</privacyPolicyUrl>',
      forgotPassword: {
        questionOnLoginForm: 'Forgot password?',
        goToLogin: 'Already have login and password?',
        passwordResetEmailSent: `Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.`,
      },
      resetPassword: {
        newPassword: 'New password',
        repeatNewPassword: 'Repeat the new password',
        resetPasswordBtn: 'Reset password',
        passwordChangedSuccessfully: 'Password changed successfully',
      },
      otp: {
        code: 'OTP code',
        qrCodeInstructions: `
          <div>
          <strong>Quick Guide: Setting Up Your Authenticator App for Enhanced Security:</strong>
          <ol>
          <li>Download: Go to your app store (App Store for iOS, Google Play Store for Android) and search for your chosen authenticator app (e.g., Google Authenticator or Authy).</li>
          <li>Install: Download and install the app on your device.</li>
          <li>Open: Open the app after installation.</li>
          <li>Add Account: Tap the plus (+) button in the app to add a new account.</li>
          <li>Scan QR Code: Choose "Scan QR Code" and use your camera to scan the QR code from the website or service.</li>
          <li>Enter Code: Enter the time-based verification code generated by the app into the website or service.</li>
          <li>Access: Use the verification code from the app whenever you log in to the website or service.</li>
          </ol>
          </div>
          `,
      },
    },
    userManagement: {
      searchQuery: 'Filter by name and email',
      tabs: {
        meinbauAdmins: 'MeinBau admins',
        tenantUsers: 'Tenant users',
        apiUsers: 'API users',
      },
      users: {
        name: 'Accounts',
        registeredAt: 'Registered at',
        addTenantUserBtn: 'Add client user',
        addTenantUserModal: {
          header: 'Add user to client',
          clientUsersField: 'Client users',
        },
        updateUserAccount: {
          modalHeader: 'Update Account',
        },
        noUsersFound: {
          description: 'No users were found for your query, please try adding them or changing your search criteria',
        },
        deleteUserFromTenant: {
          title: 'Delete',
          message: 'Are you sure, do you want to delete user from current client?',
          successfullyDeleted: 'Successfully deleted',
        },
      },
      invitations: {
        title: 'Invitations',
        role: 'Role',
        invitationDate: 'Invitation date',
        draftOrSent: 'Draft or sent',
        draft: 'Draft',
        sent: 'Sent',
        inviteUserBtn: 'Invite user',
        modalHeader: 'User invitation',
        invitationSuccessfullySent: 'Invitation successfully sent',
        retryInvitation: 'Resend',
        branches: 'Branches',
        inviter: 'Invited by',
        additionalMessage: 'Here you can write an additional, individual message for the user (optional)',
        deleteInvitation: {
          title: 'Revoke',
          message: 'Are you sure, do you want to delete this invitation for {{email}} email?',
          invitationSuccessfullyDeleted: 'Invitation successfully deleted',
        },
        noInvitationsFound: {
          description:
            'No invitations were found for your query, please try adding them or changing your search criteria',
        },
        form: {
          saveAsDraft: 'Save as draft',
          saveAndSend: 'Save and send',
        },
      },
    },
    tenantManagement: {
      baseData: {
        createdBy: 'Created by ',
        updatedBy: 'Updated by ',
        archivedBy: 'Archived by ',
        releasedBy: 'Released by ',
      },
      tabs: {
        baseData: 'Base information',
        updateTenantBtn: 'Update tenant',
        suspendTenantBtn: 'Suspend tenant',
        releaseTenantBtn: 'Release tenant',
        iframes: ' iFrames',
        users: ' Users',
      },
      alert: {
        releaseHeader: 'Release tenant',
        suspendHeader: 'Suspend tenant',
        releaseTenantAlert: 'Are you sure you want release Tenant?',
        suspendTenantAlert: 'Are you sure you want suspend Tenant?',
      },
      modal: {
        headerUpdate: 'Update Tenant',
        update: 'Update',
        headerCreate: 'Create Tenant',
        create: 'Create',
        companyNameLabel: 'Company name',
        close: 'Close',
      },
      active: 'Active',
      archived: 'Archived',
    },
    tenants: {
      active: 'Active',
      archived: 'Archived',
      createTenantBtn: 'Create Tenant',
      tableHeaders: {
        companyName: 'Company Name',
        archived: 'Archived',
        status: 'Status',
        createdBy: 'Created by',
        updatedBy: 'Updated by',
        statusChangedBy: 'Status changed by',
      },
      noTenantsFound: {
        title: 'No tenants found',
        description: 'No tenants were found for your query, please try adding them or changing your search criteria',
      },
      searchQuery: 'Filter by company name',
      status: {
        label: 'Filter by status',
        all: 'All',
        archived: 'Archived',
        active: 'Active',
      },
    },
    files: {
      deleteFileAlert: {
        title: 'Delete file',
        message: 'Do you wanna delete this file?',
      },
      table: {
        headers: {
          filename: 'Filename',
        },
      },
    },
    dnd: {
      uploadAlertWarningMessage: 'Upload in progress. If you close this page the progress will be lost',
      dropFileHere: 'Drop file here',
      acceptableFileTypes: 'Acceptable file types - {{extensions}}',
      extensionIsNotAllowed: 'Upload error \n Sorry, this extension is not allowed \n {{fileName}}',
      tooManyFiles: 'You cannot upload more than {{maxFilesCount}} files at once',
      maxFileSize: 'Maximum file size - {{size}} mb',
      maxFileSizeError: 'Upload error \n Maximum file size - {{size}} mb \nFiles {{fileNames}} skipped',
      releaseToUpload: 'Release to upload',
      uploadBtnLabel: 'Upload',
    },
    iframeAssets: {
      errorOccurredWhiteGettingFileInstances: 'An error occurred while getting file instances',
      modal: {
        header: 'Select image',
        newFileInteractionDescription: 'Choose from existing files or upload a new one',
        existingFileInteractionDescription: 'Remove the existing file in order to upload or select a new one',
        noFileSelected: 'No file selected',
        noFilesUploaded: 'No files uploaded',
      },
      types: {
        EMAIL_LOGO: 'Email logo',
        FAVICON_128: 'Favicon 128',
        UI_LOGO: 'UI logo',
        PDF_LOGO: 'Pdf logo',
        FAVICON: 'Favicon',
        APPLE_TOUCH_ICON: 'Apple touch icon',
        FAVICON_32: 'Favicon 32',
        FAVICON_16: 'Favicon 16',
        SAFARI_PINNED_TAB: 'Safari pinned tab',
        ANDROID_CHROME_192: 'Android Chrome 192',
        ANDROID_CHROME_512: 'Android Chrome 512',
        IMG_HOME_PAGE: 'Image home page',
        IMG_FIRST_STEP: 'Image first step',
        IMG_SECOND_STEP: 'Image second step',
        IMG_THIRD_STEP: 'Image third step',
        IMG_FOURTH_STEP: 'Image fourth step',
        IMG_FIFTH_STEP: 'Image fifth step',
        PARTNERS_FIRST_SECTION_BG: 'Partners - first section background',
        PARTNERS_FIRST_SECTION_LOGO: 'Partners - first section logo',
        PARTNERS_SECOND_SECTION_BG: 'Partners  - second section background',
        PARTNERS_SECOND_SECTION_LOGO: 'Partners - second section logo',
        PARTNERS_THIRD_SECTION_BG: 'Partners - third section background',
        PARTNERS_THIRD_SECTION_LOGO: 'Partners - third section logo',
      },
      table: {
        headers: {
          type: 'Type',
          file: 'File',
        },
      },
    },
    webhook: {
      addWebhookButton: 'Add webhook',
      addWebhookModal: {
        header: 'Add webhook',
      },
      updateWebhookModal: {
        header: 'Update webhook',
      },
      deleteWebhookAlert: {
        title: 'Delete webhook',
        message: 'Are you sure, do you want to delete webhook?',
      },
      form: {
        trigger: 'Trigger',
        events: 'Events',
        url: 'Url',
        headers: 'Headers',
        triggerOptions: {
          [WebhookTrigger.pdfRequestSubmission]: 'PDF request submission',
        },
        eventOptions: {
          [WebhookEvent.transferPDF]: 'Transfer PDF',
          [WebhookEvent.transferJSON]: 'Transfer JSON',
        },
      },
      headers: {
        form: {
          name: 'Name',
          value: 'Value',
        },
        table: {
          name: 'Name',
          value: 'Value',
          emptyDataMessage: 'No headers found',
        },
        headerAlreadyExists: 'Header already exists',
      },
    },
    projectTypes: {
      all: 'All',
      [ProjectType.renovatingHouse]: 'Renovation house',
      [ProjectType.newBuilding]: 'New building',
      [ProjectType.multiFamilyHouse]: 'Multi Family House',
    },
    equipmentTypes: {
      standard: 'Standard',
      premium: 'Premium',
      superior: 'Superior',
    } as Record<EquipmentType, string>,
    iframe: {
      active: 'Active',
      archived: 'Archived',
      updateIframeBtn: 'Update iframe',
      suspendIframeBtn: 'Suspend iframe',
      releaseIframeBtn: 'Release iframe',
      integrationWithPriceHubbleModal: 'PriceHubble integration modal',
      priceHubbleApiUsername: 'PriceHubble API user name',
      priceHubbleApiPassword: 'PriceHubble API password',
      integrate: 'Integrate',
      close: 'Close',
      integratePriceHubbleIframeBtn: 'Integrate with PriceHubble',
      disablePriceHubbleIframeBtn: 'Disable integration',
      integratedWithPriceHubble: 'Integrated with PH',
      notIntegratedWithPriceHubble: 'Not integrated with PH',
      integrationWithProjectPdfWebhookModal: 'Integrate Webhook project PDF',
      projectPdfWebhookClientId: 'Client-Id',
      projectPdfWebhookUrl: 'URL',
      copyLink: 'Copy Iframe link',
      copiedToClipboard: 'Iframe URL copied to clipboard',
      baseData: {
        iframeHtmlTitle: 'HTML title',
        projectTypes: 'Project types',
        primaryColor: 'Primary color',
        pdfButtonColor: 'PDF Button color',
        textColor: 'Text color',
        domainWhiteListTypes: 'Domain white list',
        headInjectionTags: 'Tags to be injected',
        releasedBy: 'Release by',
        archivedBy: 'Archived by ',
        projectTypeNotSpecified: 'Project types is not specified',
        domainWhiteListTypesNotSpecified: 'Domain white list is not specified',
        headInjectionTagsNotSpecified: 'Head injection tags is not specified',
        requestProjectPdfConfig: {
          emailFrom: 'PDF E-mail',
          subject: 'PDF E-mail subject',
          message: 'PDF E-mail message',
        },
        projectPdfConfig: {
          pdfWebsiteUrl: 'PDF Website url',
        },
        privacyPolicyUrl: 'Privacy policy url',
        termsOfUseUrl: 'Terms of use url',
      },
      alert: {
        releaseHeader: 'Release iframe',
        suspendHeader: 'Suspend iframe',
        releaseIframeAlert: 'Are you sure you want release Iframe?',
        suspendIframeAlert: 'Are you sure you want suspend Iframe?',
        disableIntegrationHeader: 'Disable Price Hubble integration',
        disableIntegrationAlert:
          'Are you sure you want disable integration? This will erase current information about API username and password',
        disableSubmissionWebhookHeader: 'Disabling submission webhook integration',
        disableSubmissionWebhookAlert: 'Are you sure you want disable submission webhook integration?',
        disableProjectPdfWebhookHeader: 'Disabling project pdf webhook integration',
        disableProjectPdfWebhookAlert: 'Are you sure you want disable project PDF webhook integration?',
        disableGTMIntegrationHeader: 'Disable GTM integration',
        disableGTMIntegrationAlert: 'Are you sure you want disable integration? This will erase current GTM key.',
        removeApiIntegrationHeader: 'Delete {{name}} integration',
        disableApiIntegrationAlert:
          'Are you sure you want to remove integration? This will erase current information about API clientId and client secret',
      },
      countries: {
        de: 'DE',
        at: 'AT',
      },
      form: {
        htmlTitle: 'HTML Title',
        headerUpdate: 'Iframe update',
        headerCreate: 'Iframe create',
        nameLabel: 'Name',
        primaryColorLabel: 'Primary Color',
        pdfButtonColorLabel: 'PDF Button color',
        textColorLabel: 'Text Color',
        projectTypeLabel: 'Project Type',
        domainWhiteList: 'Domain white list',
        headInjectionTags: 'HTML Meta tags',
        update: 'Update',
        close: 'Close',
        create: 'Create',
        contactEmail: 'Contact email',
        pdfConfig: 'PDF config',
        pdfEmailConfigEmail: 'PDF E-mail',
        useImplementationStartPoint: 'Include implementation start point?',
        usePhoneNumber: 'Include user phone number?',
        pdfEmailConfigSubject: 'PDF E-mail subject',
        pdfEmailConfigMessage: 'PDF E-mail message',
        pdfWebsiteUrl: 'PDF website url',
        privacyPolicyUrl: 'Privacy policy url',
        termsOfUseUrl: 'Terms of use url',
        pdfDownloadEnabledViaPublicRoute: 'Enable PDF Download via Public Route',
        newPdfRequestNotification: 'New PDF request notification',
        projectTemplate: 'Project template',
        country: 'Country',
        integrationServices: 'Integration services',
        integrationServiceOptions: {
          solarApi: 'Solar API',
          propertyValuation: 'Property valuation',
          aiHints: 'AI Hints',
        } as Record<IntegrationService, string>,
      },
      templates: {
        [TemplateName.hiil]: simpleDecrypt('Hill'),
        [TemplateName.vsdundvvhErghqvhh]: simpleDecrypt('Vsdundvvh Erghqvhh'),
        [TemplateName.vsdundvvhGxuhq]: simpleDecrypt('Vsdundvvh Gxuhq'),
        [TemplateName.vsdundvvhPrqfkhqjodgedfk]: simpleDecrypt('Vsdundvvh Prqfkhqjodgedfk'),
        [TemplateName.vdqlhuxqjvuhfkqhuDW]: simpleDecrypt('Vdqlhuxqjvuhfkqhu DW'),
        [TemplateName.phlqedx]: simpleDecrypt('PhlqEdx'),
        [TemplateName.uhg]: simpleDecrypt('Uhg'),
        [TemplateName.eoxh]: simpleDecrypt('Eoxh'),
        [TemplateName.kbsoQrh]: simpleDecrypt('KBSO QRH'),
        [TemplateName.hiilIxoo]: simpleDecrypt('Hiil Ixoo'),
      } as Record<TemplateName, string>,
      integrations: {
        priceHubbleConfiguration: 'PriceHubble integration',
        priceHubbleUsername: 'Username',
        priceHubbleNotConnected: 'Iframe not integrated with PriceHubble',
        gtmConfiguration: 'GTM integration',
        gtmNotConnected: 'Iframe not integrated with GTM',
        gtmKey: 'GTM key',
        apiIntegrations: 'API Integrations',
        serviceName: 'Service name',
        clientId: 'Client ID',
        secret: 'Client Secret',
        [ApiIntegrationNames.twilio]: 'Twilio',
      },
      tabs: {
        baseData: 'Iframe base data',
        integrations: 'Integrations',
        assets: 'Assets',
        projectPdfRequests: 'Iframe PDF requests',
        projects: 'Projects',
        generalCo2Report: 'General CO2 Report',
        co2ReportByProject: 'CO2 Report by project',
        translations: 'Translations',
        preview: 'Iframe preview',
        fuelMatrixConfiguration: 'Fuel Matrix configuration',
        categoryConfiguration: 'Category configuration',
        parameters: 'Parameters',
      },
      gtm: {
        modal: {
          header: 'GTM integration modal',
          gtmKey: 'GTM key',
        },
      },
    },
    co2Reports: {
      searchQuery: 'Filter by project name',
      noReportsFound: {
        description: 'There is no projects with CO2 reports',
      },
      tableHeaders: {
        co2Saving: 'CO2 Savings',
        projectsCount: 'Projects count',
        renovationName: 'Renovation name',
        name: 'Project name',
      },
      renovations: {
        newWindows: 'New windows',
        newWindowsCostSavings: 'New windows cost savings',
        ceilingTopInsulation: 'Ceiling top insulation',
        ceilingTopInsulationCostSavings: 'Ceiling top cost savings',
        ceilingBasementInsulation: 'Ceiling basement insulation',
        ceilingBasementInsulationCostSavings: 'Ceiling basement cost savings',
        facadeInsulation: 'Facade insulation',
        facadeInsulationCostSavings: 'Facade insulation cost savings',
        domesticVentilation: 'Domestic ventilation',
        domesticVentilationCostSavings: 'Domestic ventilation cost savings',
        solarPowerSystem: 'Solar power system',
        solarPowerSystemCostSavings: 'Solar power system cost savings',
        heatingSystem: 'Heating system',
        heatingSystemCostSavings: 'Heating system cost savings',
      },
    },
    changeTenant: {
      welcomeBack: 'Welcome back, {{firstName}}!',
      selectTenant: 'What client do you want to sign in?',
      searchByTenantName: 'Client name',
    },
    iframes: {
      tableHeaders: {
        name: 'Name',
        projectTypes: 'Project type',
        archived: 'Status',
      },
      searchQuery: 'Filter by name',
      createIframeBtn: 'Create iframe',
      noIframesFound: {
        description: 'No iframes were found for your query, please try adding them or changing your search criteria',
      },
    },
    projectPdfRequests: {
      searchQuery: 'Filter by project name',
      userNameNotProvided: 'Full name is not provided',
      tableHeaders: {
        userName: 'Full name',
        name: 'Project name',
        email: 'Email',
        phoneNumber: 'Phone number',
        clientIp: 'Client IP',
        pdf: 'PDF',
        createdAt: 'Created at',
      },
      noProjectPdfRequestsFound: {
        description:
          'No PDF requests were found for your query, please try adding them or changing your search criteria',
      },
    },
    projects: {
      searchQuery: 'Filter by project name',
      projectEditModal: 'Edit project',
      tableHeaders: {
        name: 'Project name',
        projectType: 'Project type',
        price: 'Price',
        clientIP: 'Client IP',
        pdf: 'PDF',
        excel: 'EXCEL',
        projectOverviewPage: 'Project overview',
        createdAt: 'Created at',
      },
      projectType: {
        label: 'Filter by project type',
        renovatingHouse: 'House renovation',
      },
      noProjectsFound: {
        description: 'No projects were found for your query, please try adding them or changing your search criteria',
      },
      downloadReportOfAllProjects: 'Generate Xlsx',
    },
    meinBauApiLogs: {
      tableHeaders: {
        appName: 'Application name',
        useCase: 'Uce-case',
        ip: 'IP',
        method: 'HTTP method',
        createdAt: 'Created at',
        status: 'Status',
      },
    },
    meinBauApiLog: {
      meinBauApiLogPageTitle: 'MeinBau API Log detail page',
      titles: {
        baseData: 'API Log general info',
        input: 'Input data',
        result: 'Result of request',
        copy: 'Copy',
      },
      baseData: {
        appName: 'Application name',
        ip: 'IP',
        httpMethod: 'HTTP Method',
        createdAt: 'Record timestamp',
        appNameNotSpecified: 'Application name no specified',
      },
      copiedToClipboard: 'Data copied to clipboard successful',
    },
    apiLogs: {
      noLogsFound: {
        description: 'No API logs were found for your query, please try adding them or changing your search criteria',
      },
      filter: {
        apiName: {
          label: 'API Name select',
          all: 'All',
          effi: 'Effi',
          priceHubble: 'PriceHubble',
        },
      },
      tableHeaders: {
        apiName: 'API Name',
        eventType: 'Event type',
        statusCode: 'Status',
        url: 'URL',
        data: 'Data',
        details: 'Details',
        method: 'Method',
        params: 'Params',
      },
    },
    apiLog: {
      apiLogPageTitle: 'API Log Page',
      titles: {
        baseData: 'Base Data',
      },
      baseData: {
        apiName: 'API Name',
        eventType: 'Event Type',
        method: 'HTTP Method',
        statusCode: 'Status code',
        url: 'URL',
        data: 'Data',
        params: 'Params',
        eventTypeNotSpecified: 'Event type not specified',
        statusCodeNotSpecified: 'Status code not specified',
        urlNotSpecified: 'URL not specified',
        dataNotSpecified: 'Data not specified',
        paramsNotSpecified: 'Params not specified',
      },
    },
    projectReports: {
      button: 'Generated project reports',
      generationHasBegun: 'Generation of Excel has begun',
      modal: {
        title: 'Project reports',
      },
      tableHeaders: {
        createdAt: 'Created at',
        rangeStartDate: 'From',
        rangeEndDate: 'To',
        status: 'Status of generation request',
        iframeName: 'Iframe name',
      },
      error: {
        failedToLoad: 'Failed to load reports',
      },
      errorModalHeader: 'File report generation report',
    },
    dashboardInfo: {
      rangePicker: {
        lastMonthDateRange: 'Last month',
        thisMonthDateRange: 'Current month',
        allTimeDateRange: 'All time',
        startTimeDateRange: 'Range start date',
        endTimeDateRange: 'Range end date',
        applyRange: 'Apply',
        startDate: 'Start date',
        endDate: 'End date',
      },
      tenantsCount: 'All created tenants',
      usersCount: 'All created/invited users',
      projectsCount: 'All created projects',
      pdfRequestsCount: 'All pdf requests',
      projectPerTenant: 'Projects per tenant',
      pdfRequestPerTenant: 'PDF requests per tenant',
      noDataTextProjects: {
        title: 'No tenants with projects',
        description: 'There is no tenants with created projects.',
      },
      noDataTextPdfRequests: {
        title: 'No tenants with PDF requests',
        description: 'There is no tenants with created PDF requests.',
      },
    },
    tenantDashboardInfo: {
      projectPerIframe: 'Projects per iframe',
      pdfRequestPerIframe: 'PDF requests per iframe',
      noDataTextProjects: {
        title: 'No iframes with projects',
        description: 'There is no iframes with created projects.',
      },
      noDataTextPdfRequests: {
        title: 'No iframes with PDF requests',
        description: 'There is no iframes with created PDF requests.',
      },
    },
    applicationManagement: {
      name: 'Applications',
      tableHeaders: {
        appName: 'Application user name',
        tenantName: 'Associated tenant',
        createdBy: 'Created by',
        createdAt: 'Created at',
        archivedBy: 'Archived by',
      },
      searchQuery: 'Search by application user name',
      modal: {
        header: 'Create Application user',
        appName: 'Application user name',
        close: 'Close',
        create: 'Create',
        applicationId: 'X-Client-Id',
        password: 'Secret',
        copiedToClipboard: 'Copied to clipboard',
        tenant: {
          label: 'Select tenant',
          noTenant: 'Without tenant',
        },
      },
      alert: {
        title: 'Archive application user?',
        message: 'This action will archive application permanently. Are you sure you want to proceed?',
      },
      toast: {
        applicationArchived: 'Application: {{appName}} archived successfully',
      },
      noApplicationsFound: { description: 'There is no application users, try to create one or change search query' },
      createApplicationAccount: 'Create Application account',
    },
    errors: {
      invalidEmail: 'Invalid email',
      requiredField: 'Required field',
      chooseOne: 'Choose one',
      passwordIsTooShort: 'Password is too short',
      passwordsMustMatch: 'Passwords must match',
      min: 'Please enter at least {{min}} characters',
      invalidUrl: 'URL is invalid',
    },
    translations: {
      updateTranslations: 'Save Result page configuration',
      resultPageConfigurationUpdated: 'Result page configuration successfully updated',
      planSection: {
        isEnabled: 'Plan Sections',
      },
      equivalentsSection: {
        isEnabled: 'Equivalents Sections',
      },
      totalCostSection: {
        isEnabled: 'Total Cost Sections',
      },
      investmentsPayOfSection: {
        isEnabled: 'Investments Pay Off Sections',
      },
      headerSection: {
        isEnabled: 'Header Section',
        header: {
          en: 'Header English',
          de: 'Header German',
        },
      },
      firstPartnerSection: {
        isEnabled: 'First Partner Section',
        header: {
          en: 'Header English',
          de: 'Header German',
        },
        content: {
          en: 'Content English',
          de: 'Content German',
        },
        pdfButton: {
          en: 'PDF Button English',
          de: 'PDF Button German',
        },
      },
      secondPartnerSection: {
        isEnabled: 'Second Partner Section',
        header: {
          en: 'Header English',
          de: 'Header German',
        },
        content: {
          en: 'Content English',
          de: 'Content German',
        },
        pdfButton: {
          en: 'PDF Button English',
          de: 'PDF Button German',
        },
      },
    },
    auditLog: {
      noLogsFound: {
        description: 'No audit logs were found for your query, please try adding them or changing your search criteria',
      },
      titles: {
        baseData: 'Base data',
        payload: 'Event payload',
      },
      baseData: {
        name: 'Audit Log name',
        entity: 'Entity',
        tenantId: 'Tenant ID',
        tenantIdIsNotSpecified: 'Tenant ID is not provided',
        createdBy: 'Created By',
        meta: {
          clientIP: 'Client IP',
          country: 'Country',
          countryIsNotSpecified: 'Country is not provided',
          domain: 'Domain',
          utmParams: 'Urchin Tracking Module(UTM) parameters',
          utmParamsIsNotSpecified: 'UTM is not provided',
        },
      },
      payload: {
        tableHeaders: {
          label: 'Field name',
          value: 'Field value',
        },
      },
      copy: 'Copy as JSON whole payload',
      auditLogPageTitle: 'Audit Log page',
      payloadIsNotProvided: 'Payload is not provided',
      copiedToClipboard: 'Copied to clipboard',
      valueCopiedToClipBoard: 'Value of {{label}} copied to clipboard',
      helper: 'To copy specific value click at table row',
    },
    auditLogs: {
      searchQuery: 'Filter by name',
      tenantQuery: 'Filter by tenant',
      startDateQuery: 'Filter by start date',
      endDateQuery: 'Filter by end date',
      tableHeaders: {
        name: 'Name',
        entity: 'Affected Entity',
        createdBy: 'Created By',
        createdAt: 'Time of record creation',
      },
    },
    fuelMatrix: {
      tableHeaders: {
        itemName: 'Name',
        input: 'Input',
        unit: 'Unit',
        avgPricePerUnit: 'Avg. Price per Unit',
        conversionFactor: 'Conversion Factor',
        resultInKwh: 'Result in kWh',
        avgPricePerKwh: 'Avg. Price per kWh',
      },
      tableRowItems: {
        heatingOil: 'Heating oil',
        naturalGas: 'Natural gas',
        coal: 'Coal',
        firewood: 'Firewood',
        woodChips: 'Wood chips',
        woodPellets: 'Wood pellets',
        electricityUsed: 'Electricity used',
        electricityProduced: 'Electricity produced',
        districtHeating: 'District heating',
      },
      lastUpdatedBy: 'Last time updated by: ',
      setToDefault: 'Set to system provided values',
      updateFuelMatrix: 'Update Fuel Matrix',
    },
    parameters: {
      name: 'Name',
      input: 'Input',
      unit: 'Unit',
      co2Footprint: {
        title: 'Co2 Footprint',
        districtHeating: 'District Heating',
        electricity: 'Electricity',
        gas: 'Gas',
        units: {
          districtHeating: 'kg/MWh',
          electricity: 'kg/MWh',
          gas: 'kg/MWh',
        },
      },
      investmentCost: {
        title: 'Investment Costs (CAPEX)',
        ascendingLinesDistributionToApartments: 'Ascending lines, distribution to apartments',
        constructionCostsApartment: 'Construction costs/apartment',
        coolingUnitApartment: 'Cooling-Unit/apartment',
        coolingUnitAreaFactor: 'Cooling-Unit (Age Faktor)',
        electricityChangesNewStoveApartment: 'Electricity changes (if old-system was gas) new stove/apartment',
        heatUnitApartment: 'Heat-Unit/apartment',
        units: {
          ascendingLinesDistributionToApartments: '€/apartment',
          constructionCostsApartment: '€',
          coolingUnitApartment: '€ (optional)',
          coolingUnitAreaFactor: '€/m2',
          electricityChangesNewStoveApartment: '€',
          heatUnitApartment: '€',
        },
      },
      constructionCosts: {
        title: 'Construction Costs',
        buildingInstallationCosts: 'Building installation-costs',
        districtHeatingConnectionNetFix: 'District Heating Connection to net fix',
        districtHeatingConnectionNetVariable: 'District Heating Connection to net variable',
        heatPumpAir: 'Heat-Pump Air',
        heatPumpBuildingPart: 'Heat-Pump building part',
        heatPumpGeothermal: 'Heat-Pump geothermal',
        units: {
          buildingInstallationCosts: '€/building (part)',
          districtHeatingConnectionNetFix: '€/object',
          districtHeatingConnectionNetVariable: '€/kW Heating Demand',
          heatPumpAir: '€/kw Heating Demand',
          heatPumpBuildingPart: '€/building (part)',
          heatPumpGeothermal: '€/kw Heating Demand',
        },
      },
      thermalParameters: {
        title: 'Thermal Parameters',
        fullLoadHoursHeating: 'Full load hours heating',
        usefulEnergyHotWater: 'Useful energy hot water',
        heatingSystemOutputSafetySurcharge: 'Heating system output - safety surcharge',
        distributionLossForCentralHeating: 'Distribution loss for central heating',
        annualPerformanceFactorDistrictHeating: 'Annual performance factor district heating',
        annualPerformanceFactorGasBoiler: 'Annual performance factor gas boiler',
        annualPerformanceFactorHeatPumpAir: 'Annual performance factor heat-pump-air',
        annualPerformanceFactorGeothermalHeatPump: 'Annual performance factor geothermal-heat-pump',
        annualPerformanceFactorElectricHeating: 'Annual performance factor electric heating',
        annualPerformanceFactorOilHeating: 'Annual performance factor oil heating',
        annualPerformanceFactorCoalHeating: 'Annual performance factor coal heating',
        ratioNetFloorAreaToGrossFloorArea: 'Ratio net floor area to gross floor area',
        units: {
          fullLoadHoursHeating: 'h/a',
          usefulEnergyHotWater: 'kWh/m²/a',
          heatingSystemOutputSafetySurcharge: '',
          distributionLossForCentralHeating: '',
          annualPerformanceFactorDistrictHeating: '',
          annualPerformanceFactorGasBoiler: '',
          annualPerformanceFactorHeatPumpAir: '',
          annualPerformanceFactorGeothermalHeatPump: '',
          annualPerformanceFactorElectricHeating: '',
          annualPerformanceFactorOilHeating: '',
          annualPerformanceFactorCoalHeating: '',
          ratioNetFloorAreaToGrossFloorArea: '',
        },
      },
      renovationCosts: {
        title: 'Renovation Costs',
        facade: 'Facade',
        pvSystem: 'PV-System',
        topCeilingInsulation: 'Top ceiling insulation',
        windows: 'Windows',
        units: {
          facade: '€/m2 estimated facade-area',
          pvSystem: '€/kWp',
          topCeilingInsulation: '€/m2 ceiling area',
          windows: '€/m2 apartment-area',
        },
      },
      renovationImpact: {
        title: 'Renovation impact (reduction heating demand)',
        facadeReduction: 'Facade',
        topCeilingInsulationReduction: 'Top ceiling insulation',
        windowsReduction: 'Windows',
        units: {
          facadeReduction: '%',
          topCeilingInsulationReduction: '%',
          windowsReduction: '%',
        },
      },
      runningCosts: {
        title: 'Running Costs',
        coalDepreciation: 'Coal - Depreciation, Reserves',
        coalOperation: 'Coal - Operation (Inspection, SmartMetering etc.)',
        districtHeatingDepreciation: 'District Heating - Depreciation, Reserves',
        districtHeatingOperation: 'District Heating - Operation (Inspection, SmartMetering etc.)',
        gasDepreciation: 'Gas - Depreciation, Reserves',
        gasOperation: 'Gas - Operation (Inspection, SmartMetering etc.)',
        heatPumpDepreciation: 'Heat-pump - Depreciation, Reserves',
        heatPumpOperation: 'Heat-pump - Operation (Inspection, SmartMetering etc.)',
        oilDepreciation: 'Oil - Depreciation, Reserves',
        oilOperation: 'Oil - Operation (Inspection, SmartMetering etc.)',
        units: {
          coalDepreciation: 'of the investment-cost /apartment/a',
          coalOperation: '€/apartment/a',
          districtHeatingDepreciation: 'of the investment-cost /apartment/a',
          districtHeatingOperation: '€/apartment/a',
          gasDepreciation: 'of the investment-cost /apartment/a',
          gasOperation: '€/apartment/a',
          heatPumpDepreciation: 'of the investment-cost /apartment/a',
          heatPumpOperation: '€/apartment/a',
          oilDepreciation: 'of the investment-cost /apartment/a',
          oilOperation: '€/apartment/a',
        },
      },
      financing: {
        financingDuration: 'Financing-Duration',
        interestRate: 'Interest-Rate',
        ownFundsRatio: 'Own Funds ratio',
        publicFundingRatio: 'Public funding ratio',
        vacancyRateAfterRenovation: 'Vacancy-Rate after renovation',
        vacancyRateBeforeRenovation: 'Vacancy-Rate before renovation',
        units: {
          financingDuration: 'years',
          interestRate: '',
          ownFundsRatio: '',
          publicFundingRatio: '',
          vacancyRateAfterRenovation: '',
          vacancyRateBeforeRenovation: '',
        },
      },
    },
    pageTitles: {
      login: 'Login',
      initTwoFA: 'Initialize 2FA',
      dashboard: 'Dashboard',
      logout: 'Logout',
      changeTenant: 'Change client',
      forgotPassword: 'Forgot password',
      resetPassword: 'Reset password',
      userManagement: 'User management',
      tenantsManagement: 'Tenants management',
      tenantManagement: 'Tenant',
      redeemInvitation: 'Redeem invitation',
      iframesManagement: 'Iframes',
      projectPdfRequests: 'Projects PDF requests',
      projects: 'Projects',
      meinBauApiLogs: 'MeinBau API Logs',
      apiCallLogs: 'API Call Logs',
      auditLogs: 'Audit Logs',
    },
    planningCategories,
    iframeCategoryConfiguration: {
      table: {
        headers: {
          parent: 'Parent category',
          category: 'Category',
          pricePerUnit: 'Price per unit',
          equipmentTypes: 'Equipment types',
        },
        body: {
          [SolePlateOrBasement.solePlate]: 'Sole plate',
          [SolePlateOrBasement.basement]: 'Basement',
        },
      },
      resetConfiguration: 'Reset configuration',
    },
  },
};

export const backendErrors = {
  TWO_FA_ERROR_NOT_ENABLED: '2FA is not enabled',
  // TWO_FA_CODE_MISSING: 'TWO_FA_CODE_MISSING', - this error shouldn't be i18n-ed
  TWO_FA_ALREADY_ENABLED: '2FA is already enabled',
  INVALID_TWO_FA_CODE: 'Invalid one-time password',
  INCORRECT_CREDENTIALS: 'Password or email is incorrect',
};
