import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Table from 'components/common/Table/Table';
import { isEqual } from 'lodash';
import { getValidationSchema } from './validationSchemas';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { InitialValues, IRow } from './types';

const TableForm = ({
  onSubmit,
  columns,
  initialValues,
  loading,
}: {
  onSubmit: (values: InitialValues) => Promise<void>;
  columns: ColumnDef<IRow>[];
  initialValues: InitialValues;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const tableData = Object.keys(initialValues || {}).map((key) => ({ name: key }));

  const validationSchema = getValidationSchema(initialValues);
  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
        validationSchema,
        enableReinitialize: true,
      }}
    >
      {({ values }) => (
        <Form>
          <Row className="justify-content-end mb-3">
            <Col md={2}>
              <Button loading={loading} type="submit" disabled={isEqual(initialValues, values)}>
                <FontAwesomeIcon icon={faSave} color="white" />
                {t('iframe.form.update')}
              </Button>
            </Col>
          </Row>
          <Table columns={columns} data={tableData} />
        </Form>
      )}
    </Formik>
  );
};

export default TableForm;
