import * as SchemaTypes from '../../../types.generated';

import { gql } from '@apollo/client';
import { EnergyFragmentDoc } from '../../../fragments/iframe/generated/EnergyFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MultiFamilyHouseSettingsQueryVariables = SchemaTypes.Exact<{
  iframeId: SchemaTypes.Scalars['ID']['input'];
}>;

export type MultiFamilyHouseSettingsQuery = {
  __typename?: 'Query';
  multiFamilyHouseSettings: {
    __typename?: 'MultiFamilyHouseSettings';
    _id: string;
    iframeId?: string | null;
    parameters: {
      __typename?: 'Parameters';
      thermalParameters: {
        __typename?: 'ThermalParameters';
        fullLoadHoursHeating: number;
        usefulEnergyHotWater: number;
        heatingSystemOutputSafetySurcharge: number;
        distributionLossForCentralHeating: number;
        annualPerformanceFactorDistrictHeating: number;
        annualPerformanceFactorGasBoiler: number;
        annualPerformanceFactorHeatPumpAir: number;
        annualPerformanceFactorGeothermalHeatPump: number;
        annualPerformanceFactorElectricHeating: number;
        annualPerformanceFactorOilHeating: number;
        annualPerformanceFactorCoalHeating: number;
        ratioNetFloorAreaToGrossFloorArea: number;
      };
      co2Footprint: { __typename?: 'Co2Footprint'; gas: number; electricity: number; districtHeating: number };
      investmentCosts: {
        __typename?: 'InvestmentCosts';
        heatUnitApartment: number;
        coolingUnitApartment: number;
        coolingUnitAreaFactor: number;
        constructionCostsApartment: number;
        electricityChangesNewStoveApartment: number;
        ascendingLinesDistributionToApartments: number;
      };
      constructionCosts: {
        __typename?: 'ConstructionCosts';
        heatPumpAir: number;
        heatPumpGeothermal: number;
        heatPumpBuildingPart: number;
        districtHeatingConnectionNetFix: number;
        districtHeatingConnectionNetVariable: number;
        buildingInstallationCosts: number;
      };
      renovationCosts: {
        __typename?: 'RenovationCosts';
        facade: number;
        windows: number;
        pvSystem: number;
        topCeilingInsulation: number;
      };
      renovationImpact: {
        __typename?: 'RenovationImpactSettings';
        facadeReduction: number;
        windowsReduction: number;
        topCeilingInsulationReduction: number;
      };
      runningCosts: {
        __typename?: 'RunningCosts';
        heatPumpOperation: number;
        gasOperation: number;
        districtHeatingOperation: number;
        oilOperation: number;
        coalOperation: number;
        heatPumpDepreciation: number;
        gasDepreciation: number;
        districtHeatingDepreciation: number;
        oilDepreciation: number;
        coalDepreciation: number;
      };
      financing: {
        __typename?: 'Financing';
        ownFundsRatio: number;
        publicFundingRatio: number;
        interestRate: number;
        financingDuration: number;
        vacancyRateBeforeRenovation: number;
        vacancyRateAfterRenovation: number;
      };
    };
    energyStandards: Array<{
      __typename?: 'EnergyStandardSettings';
      constructionYearRange: SchemaTypes.MultiFamilyHouseEnergyStandard;
      heatingEnergyDemand: number;
      ageFactor: number;
    }>;
    energy: {
      __typename?: 'EnergyClassSettings';
      AT: Array<{
        __typename?: 'Energy';
        energyClass: string;
        hwb: number;
        peb: number;
        co2: number;
        efficiencyFactor: number;
      }>;
      DE: Array<{
        __typename?: 'Energy';
        energyClass: string;
        hwb: number;
        peb: number;
        co2: number;
        efficiencyFactor: number;
      }>;
    };
  };
};

export const MultiFamilyHouseSettingsDocument = gql`
  query MultiFamilyHouseSettings($iframeId: ID!) {
    multiFamilyHouseSettings(iframeId: $iframeId) {
      _id
      iframeId
      parameters {
        thermalParameters {
          fullLoadHoursHeating
          usefulEnergyHotWater
          heatingSystemOutputSafetySurcharge
          distributionLossForCentralHeating
          annualPerformanceFactorDistrictHeating
          annualPerformanceFactorGasBoiler
          annualPerformanceFactorHeatPumpAir
          annualPerformanceFactorGeothermalHeatPump
          annualPerformanceFactorElectricHeating
          annualPerformanceFactorOilHeating
          annualPerformanceFactorCoalHeating
          ratioNetFloorAreaToGrossFloorArea
        }
        co2Footprint {
          gas
          electricity
          districtHeating
        }
        investmentCosts {
          heatUnitApartment
          coolingUnitApartment
          coolingUnitAreaFactor
          constructionCostsApartment
          electricityChangesNewStoveApartment
          ascendingLinesDistributionToApartments
        }
        constructionCosts {
          heatPumpAir
          heatPumpGeothermal
          heatPumpBuildingPart
          districtHeatingConnectionNetFix
          districtHeatingConnectionNetVariable
          buildingInstallationCosts
        }
        renovationCosts {
          facade
          windows
          pvSystem
          topCeilingInsulation
        }
        renovationImpact {
          facadeReduction
          windowsReduction
          topCeilingInsulationReduction
        }
        runningCosts {
          heatPumpOperation
          gasOperation
          districtHeatingOperation
          oilOperation
          coalOperation
          heatPumpDepreciation
          gasDepreciation
          districtHeatingDepreciation
          oilDepreciation
          coalDepreciation
        }
        financing {
          ownFundsRatio
          publicFundingRatio
          interestRate
          financingDuration
          vacancyRateBeforeRenovation
          vacancyRateAfterRenovation
        }
      }
      energyStandards {
        constructionYearRange
        heatingEnergyDemand
        ageFactor
      }
      energy {
        AT {
          ...Energy
        }
        DE {
          ...Energy
        }
      }
    }
  }
  ${EnergyFragmentDoc}
`;

/**
 * __useMultiFamilyHouseSettingsQuery__
 *
 * To run a query within a React component, call `useMultiFamilyHouseSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiFamilyHouseSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiFamilyHouseSettingsQuery({
 *   variables: {
 *      iframeId: // value for 'iframeId'
 *   },
 * });
 */
export function useMultiFamilyHouseSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<MultiFamilyHouseSettingsQuery, MultiFamilyHouseSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MultiFamilyHouseSettingsQuery, MultiFamilyHouseSettingsQueryVariables>(
    MultiFamilyHouseSettingsDocument,
    options,
  );
}
export function useMultiFamilyHouseSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MultiFamilyHouseSettingsQuery, MultiFamilyHouseSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MultiFamilyHouseSettingsQuery, MultiFamilyHouseSettingsQueryVariables>(
    MultiFamilyHouseSettingsDocument,
    options,
  );
}
export type MultiFamilyHouseSettingsQueryHookResult = ReturnType<typeof useMultiFamilyHouseSettingsQuery>;
export type MultiFamilyHouseSettingsLazyQueryHookResult = ReturnType<typeof useMultiFamilyHouseSettingsLazyQuery>;
export type MultiFamilyHouseSettingsQueryResult = Apollo.QueryResult<
  MultiFamilyHouseSettingsQuery,
  MultiFamilyHouseSettingsQueryVariables
>;
export function refetchMultiFamilyHouseSettingsQuery(variables: MultiFamilyHouseSettingsQueryVariables) {
  return { query: MultiFamilyHouseSettingsDocument, variables: variables };
}
