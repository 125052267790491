import React from 'react';
import { toastErrorMessage } from 'utils/error';
import Loading from 'components/common/Loading/Loading';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThermalParameters from './ThermalParameters';
import { useMultiFamilyHouseSettingsQuery } from 'graphql/queries/multiFamilyHouse/generated/MultiFamilyHouseSettings';
import Co2Footprint from './Co2Footprint';
import InvestmentCost from './InvestmentCost';
import ConstructionCosts from './ConstructionCosts';
import RenovationCosts from './RenovationCosts';
import RenovationImpact from './RenovationImpact';
import RunningCosts from './RunningCosts';
import Financing from './Financing';

const Parameters = ({ iframeId }: { iframeId: string }) => {
  const { data, loading } = useMultiFamilyHouseSettingsQuery({
    variables: { iframeId },
    onError: toastErrorMessage,
  });

  // data?.multiFamilyHouseSettings.parameters.constructionCosts
  const { t } = useTranslation();

  if (loading) return <Loading />;
  return (
    <Accordion>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.thermalParameters.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <ThermalParameters data={data?.multiFamilyHouseSettings.parameters.thermalParameters} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.co2Footprint.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Co2Footprint data={data?.multiFamilyHouseSettings.parameters.co2Footprint} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.investmentCost.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <InvestmentCost data={data?.multiFamilyHouseSettings.parameters.investmentCosts} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.constructionCosts.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <ConstructionCosts data={data?.multiFamilyHouseSettings.parameters.constructionCosts} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.renovationCosts.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <RenovationCosts data={data?.multiFamilyHouseSettings.parameters.renovationCosts} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.renovationImpact.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <RenovationImpact data={data?.multiFamilyHouseSettings.parameters.renovationImpact} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.runningCosts.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <RunningCosts data={data?.multiFamilyHouseSettings.parameters.runningCosts} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>
          <Row>
            <Col md={1}>
              <FontAwesomeIcon icon={faEdit} color="#3367d6" />
            </Col>
            <Col>{t('parameters.financing.title')}</Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Financing data={data?.multiFamilyHouseSettings.parameters.financing} iframeId={iframeId} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
// Financing

export default Parameters;
