import React from 'react';
import { toastSuccessMessage } from 'utils/error';
import { RenovationCostsInput } from 'graphql/types.generated';
import { omit } from 'lodash';
import { useUpdateMultiFamilyParametersMutation } from 'graphql/mutations/iframe/generated/UpdateMultiFamilyParameters';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { InitialValues, IRenovationCostsProps, IRow } from './types';
import InputField from './InputField';
import TableForm from './TableForm';

const RenovationCosts = ({ iframeId, data }: IRenovationCostsProps) => {
  const { t } = useTranslation();
  const [update, { loading }] = useUpdateMultiFamilyParametersMutation();
  const columns: ColumnDef<IRow>[] = [
    {
      header: t('parameters.name'),
      accessorKey: 'name',
      cell: (info) => t(`parameters.renovationCosts.${info.getValue() as string}`),
    },
    {
      header: t('parameters.input'),
      accessorKey: 'input',
      cell: (info) => <InputField name={info.row.original.name} />,
    },
    {
      header: t('parameters.unit'),
      accessorKey: 'unit',
      cell: (info) => t(`parameters.renovationCosts.units.${info.row.original.name}`),
    },
  ];

  const initialValues = omit(data, ['__typename']);

  const onSubmit = async (values: InitialValues) => {
    await update({
      variables: { iframeId, input: { renovationCosts: values as RenovationCostsInput } },
      onCompleted: () => toastSuccessMessage(t),
    });
  };
  return <TableForm {...{ columns, loading, onSubmit, initialValues }} />;
};

export default RenovationCosts;
